import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Grid,
  Paper,
  Tabs,
  Tab,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AppsIcon from '@mui/icons-material/Apps';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/Close';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { apiURL } from '../api';
import { message } from 'antd';

// Helper function to map icon names to actual React components
const iconMap = {
  AdsClickIcon: <AdsClickIcon />,
  AppsIcon: <AppsIcon />,
  TelegramIcon: <TelegramIcon />,
  YouTubeIcon: <YouTubeIcon />,
  XIcon: <XIcon />,
  FacebookIcon: <FacebookIcon />,
  InstagramIcon: <InstagramIcon />,
};

const getIcon = (iconName) => {
  return iconMap[iconName] || <MonetizationOnIcon />; // Default icon if not found
};

// Animations
const slideIn = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floating = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const glow = keyframes`
  0% {
    text-shadow: 0 0 5px #fff, 0 0 10px #f1c40f, 0 0 15px #f1c40f, 0 0 20px #f1c40f;
  }
  100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #f1c40f, 0 0 30px #f1c40f, 0 0 40px #f1c40f;
  }
`;
// Styled component for "Total Task Done"
const TotalTaskDoneBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  background: 'linear-gradient(135deg, #3500ff, #6618cb);',
  color: '#fff',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(255, 193, 7, 0.7)',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  // animation: `${glow} 2s infinite ease-in-out`,
  marginBottom: '15px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    padding: '2px',
  },
}));

const formatNumber = (num) => {
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num;
};

// Styled component for animated countdown message
const AnimatedMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#F1C40F',
  fontSize: '1.5rem',
  // animation: `${glowM} 1.5s infinite ease-in-out`,
  padding: '10px',
  borderRadius: '12px',
  boxShadow: '0px 10px 20px rgba(241, 196, 15, 0.5)',
}));

// Styled components
const AnimatedModal = styled(Box)(({ theme }) => ({
  animation: `${slideIn} 0.6s ease-out`,
  background: 'linear-gradient(135deg, #110830 0%, #4A00E0 100%)',
  padding: '20px',
  borderRadius: '20px',
  width: '90%',
  maxWidth: '400px',
  margin: '4vh auto',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.5)',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
    maxWidth: '95%',
  },
}));

const TaskCard = styled(Paper)(({ theme, status }) => ({
  borderRadius: '12px',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  background: '#ffffff',
  color: '#333',
  boxShadow: status === 'completed' ? '0px 6px 20px rgba(76, 175, 80, 0.3)' : '0px 6px 20px rgba(0, 0, 0, 0.1)',
  border: status === 'completed' ? '2px solid #81C784' : 'none',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  // animation: `${status === 'completed' ? '' : pulse} 2s infinite`,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.2)',
  },
}));

const GradientIconWrapper = styled('div')(({ color }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  background: `linear-gradient(135deg, ${color}, ${color}CC)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '15px',
  boxShadow: `0px 4px 12px ${color}`,
  color: '#fff',
  // animation: `${floating} 3s ease-in-out infinite`,
}));

// Main componentdfgh
const TaskModal = ({ open, handleClose, tasks, telegram_id, userData, fetchUserData = f => f }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const [timerActive, setTimerActive] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (window.show_8518897) return;

    const tag = document.createElement('script');
    tag.src = '//niphaumeenses.net/vignette.min.js';
    tag.dataset.zone = '8518897';
    tag.dataset.sdk = 'show_8518897';

    document.body.appendChild(tag);

    return () => {
      document.body.removeChild(tag);
    };
  }, []);

  const showAd = () => {
    if (window.show_8518897) {
      window.show_8518897().then(() => {
        //  alert("Ad has been viewed");
        //  window.Telegram.WebApp.showAlert("Reward was add");
        message.success("Nice job! You've earned a reward. Watch another ad for more!");
        // handleClose();
        grantReward(8);
      }).catch((error) => {
        console.error("Error showing the ad:", error);
      });
    } else {
      console.error("Ad function is not loaded yet.");
    }
  };

  // Handles task click
  // const handleTaskClick = (task) => {

  //     setSelectedTask(task);
  //     setConfirmationOpen(true); // Open confirmation dialog for uncompleted tasks

  // };

  // const startCountdown = () => {
  //   setTimerActive(true);
  //   let timeLeft = 10;
  //   setCountdown(timeLeft);

  //   const timer = setInterval(() => {
  //     timeLeft -= 1;
  //     setCountdown(timeLeft);

  //     if (timeLeft <= 0) {
  //       clearInterval(timer);
  //       setTimerActive(false);
  //       handleClose();
  //       // Grant reward logic
  //       grantReward();
  //     }
  //   }, 1000);
  // };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setConfirmationOpen(true); // Open confirmation dialog for uncompleted tasks
  };

  const startCountdown = () => {
    setTimerActive(true);
    let timeLeft = 15;
    setCountdown(timeLeft);

    const timer = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(timer);
        setTimerActive(false);
        handleClose();
        grantReward(); // Grant reward logic
      }
    }, 1000);
  };

  const handleWatchAdsClick = () => {
    setSelectedTask({
      name: "Watch Ads",
      icon: "AdsClickIcon",
      color: "#FF9800",
      reward: 800,
    });
    showAd()
    // setConfirmationOpen(true);
  };

  const handleConfirmTask = () => {
    if (selectedTask.link) {
      const taskWindow = window.open(selectedTask.link, '_blank');
      setConfirmationOpen(false);

      // Listen for visibility changes
      const handleVisibilityChange = () => {
        if (!document.hidden) {

          startCountdown()
          window.Telegram.WebApp.showAlert('You need to spend at least 10 seconds on the external page to receive rewards. Please go back.');

        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Cleanup when the modal is closed or the task is completed
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup visibility change listener on component unmount
      document.removeEventListener('visibilitychange', handleConfirmTask);
    };
  }, []);


  const grantReward = async (id) => {
    // alert(id);
    try {
      await axios.post(`${apiURL}task/user/complete/tasks`, {
        telegram_id: telegram_id,
        task_id: selectedTask?.id || id, // Use selectedTask.id if it exists, otherwise use id
      });
      fetchUserData();
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };



  const filteredTasks = tabValue === 0 ? tasks.incomplete : tasks.completed;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backdropFilter: 'blur(5px)' },
      }}
    >
      <Fade in={open}>
        <AnimatedModal>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
              color: '#FFFFFF',
              '&:hover': { color: '#FFCDD2' },
            }}
          />

          {/* Countdown Timer */}


          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <MonetizationOnIcon
              sx={{
                fontSize: 50,
                color: '#F1C40F',
                animation: `${glow} 2s infinite`,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                color: '#FFFFFF',
                mt: 1,
              }}
            >
              Earn More Coins
            </Typography>
          </Box>

          {/* Countdown Message */}
          {timerActive && countdown > 0 && (
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <AnimatedMessage>
                Please wait {countdown} seconds for your reward!
              </AnimatedMessage>
            </Box>
          )}


          {/* Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              mb: 3,
              backgroundColor: '#6200ea',
              borderRadius: '8px',
              '& .MuiTabs-indicator': {
                backgroundColor: '#F1C40F',
              },
            }}
          >
            <Tab
              label="Tasks"
              sx={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '0.875rem',
              }}
            />
            <Tab
              label="Completed"
              sx={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '0.875rem',
              }}
            />
          </Tabs>

          {/* Task List */}
          <Grid container spacing={2}>
            {/* Watch Ads Button */}
            <Grid item xs={12}>
              <TotalTaskDoneBox>
                <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Total Tasks Clicks: {formatNumber(userData?.count_task_done)}
                </Typography>
              </TotalTaskDoneBox>
            </Grid>

            <Grid item xs={12}>
              <TaskCard onClick={showAd}>
                <GradientIconWrapper color="#FF9800">
                  <AdsClickIcon />
                </GradientIconWrapper>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1rem' }}>
                    Watch Ads
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#555',
                      fontSize: '0.85rem',
                      animation: `${glow} 1.5s infinite`,
                    }}>
                    Reward: +700 coins
                  </Typography>
                </Box>
              </TaskCard>
            </Grid>
            {filteredTasks.map((task) => (
              <Grid
                key={task.id}
                item
                xs={12}
                onClick={() => !timerActive && handleTaskClick(task)} // Prevent clicking when timer is active
                style={{ pointerEvents: timerActive ? 'none' : 'auto' }} // Disable click events when timer is active
              >

                <TaskCard status={task.status}>
                  <GradientIconWrapper color={task.color}>
                    {getIcon(task.icon)}
                  </GradientIconWrapper>
                  <Box sx={{ flex: 1, textAlign: 'left' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        fontSize: '1rem',
                        textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                      }}
                    >
                      {task.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#555',
                        fontSize: '0.85rem',
                        animation: `${glow} 1.5s infinite`,
                      }}
                    >
                      Reward: {task.reward || 'N/A'} coins
                    </Typography>
                  </Box>

                  {/* Action Button as Icon */}
                  {task.status === 'active' && task.link && (
                    <IconButton
                      onClick={() => handleTaskClick(task)}
                      sx={{
                        color: '#F1C40F',
                        '&:hover': {
                          color: '#D4AC0D',
                        },
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  )}
                </TaskCard>

              </Grid>
            ))}
          </Grid>

          <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
            <DialogTitle>Task Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You will be taken to an external page. Rewards will be credited after confirmation.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirmTask} color="primary">
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        </AnimatedModal>
      </Fade>
    </Modal>
  );
};

export default TaskModal;
