import React, { useState, useEffect } from 'react';
import CoinApp from './components/CoinApp';
import { ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { apiURL } from './api';
import './App.css';
import './css/customStyle.css';
import { Vortex } from 'react-loader-spinner';

const theme = createTheme();
const telApp = window.Telegram?.WebApp;
const isPhone = window.innerWidth < 600;
const url = window.location.href;
const telegram_id = url.split('/').pop();

function App() {
  const [userData, setUserData] = useState(null);
  const [profileUrl, setProfileUrl] = useState("");
  const [pointCount, setPointCount] = useState(0);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(true);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [taskList, setTaskList] = useState({ incomplete: [], completed: [] });
  const [userCurrentReferrals, setUserCurrentReferrals] = useState(0);
  const [userReferralsInfo, setUserReferralsInfo] = useState([]);

  // console.log("userData?.perClick", userData?.perClick)

  const [miningInfo, setMiningInfo] = useState({
    status: 'idle',
    perClick: userData?.perClick,
    limit: userData?.energy,
    max: userData?.energy,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.show_8518897) return;

    const tag = document.createElement('script');
    tag.src = '//niphaumeenses.net/vignette.min.js';
    tag.dataset.zone = '8518897';
    tag.dataset.sdk = 'show_8518897';

    document.body.appendChild(tag);

    return () => {
        document.body.removeChild(tag);
    };
}, []);


  // After userData is fetched, update miningInfo with real values
  useEffect(() => {
    if (userData) {
      setMiningInfo({
        status: 'idle',
        perClick: userData.perClick || 0, // Fallback to 0 if undefined
        limit: userData.energy || 0,      // Fallback to 0 if undefined
        max: userData.energy || 0,        // Fallback to 0 if undefined
      });
    }
  }, [userData]);

  // Function to fetch all data concurrently
  const fetchUserData = async () => {
    try {
      const [userResponse, tasksResponse, leaderboardResponse] = await Promise.all([
        axios.get(`${apiURL}user/${telegram_id}`),
        axios.get(`${apiURL}tasks/all/incomplate/tasks/${telegram_id}`),
        axios.get(`${apiURL}leaderboard`)
      ]);

      // Update state with the fetched data
      setUserData(userResponse?.data);
      setPointCount(userResponse?.data?.points);
      setUserCurrentReferrals(userResponse.data.referrals);
      setUserReferralsInfo(userResponse.data.ReferralsAsReferrer);

      setTaskList({
        incomplete: tasksResponse.data.incompleteTasks,
        completed: tasksResponse.data.completedTasks,
      });
      setLeaderboardList(leaderboardResponse.data);

      setLoading(false); // Turn off the loader when data is fetched
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      // Set the header color to black (#000000)
      const webApp = window.Telegram.WebApp;

      // Ensure the WebApp is ready before making API calls
      webApp.onEvent('themeChanged', () => {
        webApp.setHeaderColor('#1e0947'); // Solid color only
      });

      // Set the background color of the bottom bar (must be a solid color)
      webApp.setBackgroundColor('#1e0947');

      // Open the WebApp if not opened already
      webApp.expand();
    }
  }, []);

  useEffect(() => {
    telApp.ready();
    init();

    fetchUserData();
  }, [telegram_id]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      getUserProfile();
      handleMiningInfo();
      setLoading(false); // Set loading to false only when userData is ready
    }
  }, [userData]);

  const init = () => {
    setIsTelegramMiniApp(true);
  };

  const getUserProfile = async () => {
    setProfileUrl("");
  };

  const handleMiningInfo = () => {
    if (!userData) return;

    setMiningInfo(prevMiningInfo => ({
      ...prevMiningInfo,
      limit: userData?.energy,
    }));
  };

  return (
    <>
      <div className="App">
        {loading ? (
          <div style={{
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle, rgb(17, 8, 48) 75%, rgb(17, 8, 48))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Vortex
              visible={true}
              height="250"
              width="250"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
          </div>
        ) : (
          isPhone && isTelegramMiniApp ? (
            <ThemeProvider theme={theme}>
              <CoinApp
                userData={userData}
                profileUrl={profileUrl}
                telApp={telApp}
                telegram_id={userData?.telegram_id} // Use optional chaining
                pointCount={pointCount}
                setPointCount={setPointCount}
                miningInfo={miningInfo}
                setMiningInfo={setMiningInfo}
                fetchUserData={fetchUserData}
                taskList={taskList}
                leaderboardList={leaderboardList}
                userCurrentReferrals={userCurrentReferrals}
                userReferralsInfo={userReferralsInfo}
              />
            </ThemeProvider>
          ) : (
            <div style={{ height: '110vh' }}>
              <h3 style={{ textAlign: 'center', background: 'rgb(216 215 215 / 42%)', display: 'inline-flex', padding: '20px', marginTop: '40vh', borderRadius: '20px' }}>
                You need to open with the Telegram bot!
              </h3>
              <h3>
                <a href='https://t.me/zakscoin_bot' style={{ textDecoration: 'none', color: 'darkmagenta' }}>
                  <img style={{ verticalAlign: 'middle', marginBottom: '16px' }} width="70" height="70" src="https://img.icons8.com/3d-fluency/94/robot-1.png" alt="robot-1" />
                  <span> Go to Zakscoin_bot </span>
                </a>
              </h3>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default App;
